<template>
  <Header />
  <HeroImage />
  <PrivateDining />
  <GeneralInfo />
  <Parallax />
  <Gallery />
  <HistoryBanner />
  <TheTeam />
  <OurSchedule />
  <ContactUs />
  <MenuCard />
  <Location />
  <Footer />
</template>

<script>
// this is the overall components, all subcomponents must be included here
import Header from "./Header";
import HeroImage from "./HeroImage";
import Footer from "./Footer";
import ContactUs from "./ContactUs";
import Location from "./Location";
import GeneralInfo from "./GeneralInfo";
import OurSchedule from "./OurSchedule";
import PrivateDining from "./PrivateDining";
import MenuCard from "./MenuCard";
import Gallery from "./Gallery";
import HistoryBanner from "./HistoryBanner";
import TheTeam from "./TheTeam";
import Parallax from "./Parallax"

export default {
  name: "Landing",
  components: {
    Header,
    HeroImage,
    Footer,
    Location,
    ContactUs,
    OurSchedule,
    GeneralInfo,
    PrivateDining,
    MenuCard,
    Gallery,
    HistoryBanner,
    TheTeam,
    Parallax
  },
};
</script>

<style scoped></style>
