<template>
  <div class="parallax">
    <div class="darker-overlay">
      <div class="extra-info">
        <h2 class="font-4 color-3 extra-info__title">{{ htaTitle }}</h2>
        <p class="font-4 color-3 extra-info__description">{{ htaDescription }}</p>
        <a href="tel:+34 932 31 77 16" class="btn btn-primary extra-info__btn">
          <i class="fas fa-phone"></i> reserve</a
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Parallex",
  computed: {
    htaTitle: function () {
      return this.getHtaData().title;
    },
    htaDescription: function () {
      return this.getHtaData().description;
    },
  },
  methods: {
    getHtaData: function () {
      return this.$store.getters.currentData.salaSection;
    },
  },
};
</script>
<style lang="scss">
.parallax {
  /* The image used */
  background-image: url("../../assets/img/hall.jpg");

  /* Set a specific height */
  min-height: 500px;
  position: relative;

  /* Adjust for iOS devices */
  @media only screen and (max-width: 800px) {
    background-attachment: scroll;
  }
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 76px;
}
.darker-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  padding-top: 250px;
  padding-bottom: 250px;
}
.extra-info {
  font-size: 1.5rem;
  text-align: left;
  position: absolute;
  top: 100px;
  left: 50px;
  text-shadow: 1px 1px 2px #a42e0e;
  font-weight: bolder;

  @media only screen and (min-width: 800px) {
    font-size: 2rem;
    top: 150px;
    left: 100px;
    width: 60%;
  }
  &__btn {
    background-color: #0b658f !important;
    margin-top: 1rem;
  }

  &__title {
    font-size: 36px;
    @media only screen and (min-width: 800px) {
      font-size: 46px;
    }
  }
  &__description {
    font-size: 20px;
    @media only screen and (min-width: 800px) {
      font-size: 24px;
    }
  }
}
</style>
